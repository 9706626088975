<template>
  <div>
    <!-- 内容区 -->
    <Card :bordered="false" dis-hover class="ivu-mt">
      <Tabs @on-click="handleClickTab" class="l-m-b20">
        <TabPane :label="item.name" :name="item.type" v-for="(item, index) in headeTabs" :key="index" />
      </Tabs>
      <Form
        ref="userFrom"
        :model="usersFromWhere"
        :label-width="labelWidth"
        :label-position="labelPosition"
        @submit.native.prevent>
        <Row :gutter="24">
          <Col v-bind="grid">
            <FormItem label="用户搜索：" label-for="nickname">
              <Input v-model="usersFromWhere.nickname" placeholder="请输入用户" element-id="nickname" clearable>
                <Select v-model="field_key" slot="prepend" style="width: 80px" translate="">
                  <Option value="all">全部</Option>
                  <Option value="uid">UID</Option>
                  <Option value="phone">手机号</Option>
                  <Option value="nickname">用户昵称</Option>
                </Select>
              </Input>
            </FormItem>
          </Col>
          <Col span="12" class="ivu-text-left userFrom" v-if="!collapse">
            <FormItem>
              <Button type="primary" icon="ios-search" label="default" class="l-m-r15" @click="handleUsersSearchs">搜索</Button>
              <Button class="ResetSearch" @click="handleUsersReset('userFrom')">重置</Button>
              <a class="l-m-l8 l-fontsize14" @click="collapse = !collapse">
                <template v-if="!collapse"> 展开 <Icon type="ios-arrow-down" /> </template>
                <template v-else> 收起 <Icon type="ios-arrow-up" /> </template>
              </a>
            </FormItem>
          </Col>
          <template v-if="collapse">
            <Col span="24" class="l-flex">
              <Col v-bind="grid">
                <FormItem label="用户标签：" label-for="label_id">
                  <div class="labelInput acea-row row-between-wrapper" @click="openSelectLabel">
                    <div style="width: 90%">
                      <div v-if="usersLabelData.length">
                        <Tag
                          :closable="false"
                          v-for="(item, index) in usersLabelData"
                          @on-close="closeLabel(item)"
                          :key="index">{{ item.label_name }}</Tag>
                      </div>
                      <span class="span" v-else>选择用户关联标签</span>
                    </div>
                    <div class="ivu-icon ivu-icon-ios-arrow-down"></div>
                  </div>
                </FormItem>
              </Col>
              <Col v-bind="grid">
                <FormItem label="下单次数：" label-for="pay_count">
                  <Select v-model="pay_count" placeholder="请选择下单次数" element-id="pay_count" clearable>
                    <Option value="all">全部</Option>
                    <Option value="-1">0次</Option>
                    <Option value="0">1次以上</Option>
                    <Option value="1">2次以上</Option>
                    <Option value="2">3次以上</Option>
                    <Option value="3">4次以上</Option>
                    <Option value="4">5次以上</Option>
                  </Select>
                </FormItem>
              </Col>
            </Col>
            <Col span="24" class="l-flex">
              <Col v-bind="grid">
                <FormItem label="用户分组：" label-for="group_id">
                  <Select v-model="group_id" placeholder="请选择用户分组" element-id="group_id" clearable>
                    <Option value="all">全部</Option>
                    <Option :value="item.id" v-for="(item, index) in usersGroupDataList" :key="index">{{ item.group_name }}</Option>
                  </Select>
                </FormItem>
              </Col>
              <Col v-bind="grid">
                <FormItem label="用户等级：" label-for="level">
                  <Select v-model="level_id" placeholder="请选择用户等级" element-id="level" clearable>
                    <Option value="all">全部</Option>
                    <Option :value="item.id" v-for="(item, index) in usersLevelDataList" :key="index">{{ item.name }}</Option>
                  </Select>
                </FormItem>
              </Col>
            </Col>
            <Col span="24" class="l-flex">
              <Col v-bind="grid">
                <FormItem label="性别：" label-for="sex">
                  <RadioGroup v-model="usersFromWhere.sex" type="button">
                    <Radio label=""><span>全部</span></Radio>
                    <Radio label="1"><span>男</span></Radio>
                    <Radio label="2"><span>女</span></Radio>
                    <Radio label="0"><span>保密</span></Radio>
                  </RadioGroup>
                </FormItem>
              </Col>
              <Col v-bind="grid">
                <FormItem label="身份：" label-for="is_promoter">
                  <RadioGroup v-model="usersFromWhere.is_promoter" type="button">
                    <Radio label=""><span>全部</span></Radio>
                    <Radio label="1"><span>推广员</span></Radio>
                    <Radio label="0"><span>普通用户</span></Radio>
                  </RadioGroup>
                </FormItem>
              </Col>
              <Col v-bind="grid">
                <FormItem label="付费会员：" label-for="isMember">
                  <RadioGroup v-model="usersFromWhere.isMember" type="button">
                    <Radio label=""><span>全部</span></Radio>
                    <Radio label="1"><span>是</span></Radio>
                    <Radio label="0"><span>否</span></Radio>
                  </RadioGroup>
                </FormItem>
              </Col>
            </Col>
            <Col span="24" class="l-flex">
              <Col v-bind="grid">
                <FormItem label="访问情况：" label-for="user_time_type">
                  <Select v-model="user_time_type" placeholder="请选择访问情况" element-id="user_time_type" clearable>
                    <Option value="">全部</Option>
                    <Option value="visitno">时间段未访问</Option>
                    <Option value="visit">时间段访问过</Option>
                    <Option value="add_time">首次访问</Option>
                  </Select>
                </FormItem>
              </Col>
              <Col v-bind="grid" v-if="user_time_type">
                <FormItem label="访问时间：" label-for="user_time">
                  <DatePicker
                    :editable="false"
                    @on-change="handleChangeTime"
                    :value="timeVal"
                    format="yyyy/MM/dd"
                    type="datetimerange"
                    placement="bottom-start"
                    placeholder="请选择访问时间"
                    style="width: 300px"
                    class="mr20"
                    :options="options"
                  ></DatePicker>
                </FormItem>
              </Col>
            </Col>
            <Col span="24" class="l-flex">
              <Col v-bind="grid">
                <FormItem label="地区：" label-for="country">
                  <Select
                    v-model="usersFromWhere.country"
                    placeholder="请选择国家"
                    element-id="country"
                    clearable
                    @on-change="handleChangeCountry">
                    <Option value="domestic">中国</Option>
                    <Option value="abroad">外国</Option>
                  </Select>
                </FormItem>
              </Col>
              <Col v-bind="grid" v-if="usersFromWhere.country === 'domestic'">
                <FormItem label="省份：">
                  <Cascader :data="addresDataList" :value="address" v-model="address" @on-change="handleChange"></Cascader>
                </FormItem>
              </Col>
            </Col>
          </template>
        </Row>
        <Row v-if="collapse">
          <Col span="13" class="ivu-text-right userFrom">
            <FormItem>
              <Button type="primary" icon="ios-search" label="default" class="l-m-r15" @click="handleUsersSearchs">搜索</Button>
              <Button class="ResetSearch" @click="handleUsersReset('userFrom')">重置</Button>
              <a class="l-m-l8 l-fontsize14" @click="collapse = !collapse">
                <template v-if="!collapse"> 展开 <Icon type="ios-arrow-down" /> </template>
                <template v-else> 收起 <Icon type="ios-arrow-up" /> </template>
              </a>
            </FormItem>
          </Col>
        </Row>
      </Form>
      <Divider dashed />
      <Row type="flex" justify="space-between" class="l-m-t-20">
        <Col span="24">
          <Button v-auths="[`${$config.uniquePrefix}users:create`]" type="primary" class="l-m-r10" @click="handleUsersCreate({ uid: 0 })">添加用户</Button>
          <Button v-auths="[`${$config.uniquePrefix}users:sendCoupon`]" class="l-m-r10" @click="handleUsersSendTicket">发送优惠券</Button>
          <Button
            v-auths="['admin-wechat-news']"
            class="greens mr10"
            size="default"
            @click="onSendPic"
            v-if="usersFromWhere.user_type === 'wechat'">
            <Icon type="md-list"></Icon>
            发送图文消息
          </Button>
          <Button v-auths="[`${$config.uniquePrefix}users:setGroup`]" class="l-m-r10" @click="handleUsersSetGroup">批量设置分组</Button>
          <Button v-auths="[`${$config.uniquePrefix}users:setLabel`]" class="l-m-r10" @click="handleUsersSetLabel">批量设置标签</Button>
          <Button class="l-m-r10" icon="ios-share-outline" @click="handleUsersExportList">导出</Button>
          <Button v-auths="[`${$config.uniquePrefix}users:synchro`]" class="l-m-r10" @click="handleUsersSynchro">同步公众号用户</Button>
        </Col>
        <Col span="24" class="userAlert" v-if="selectionUsersList.length">
          <Alert show-icon>已选择<i class="userI"> {{ selectionUsersList.length }} </i>项</Alert>
        </Col>
      </Row>
      <Table
        :columns="usersColumns"
        :data="usersListData"
        class="l-m-t10"
        ref="table"
        highlight-row
        :loading="loading"
        no-userFrom-text="暂无数据"
        no-filtered-userFrom-text="暂无筛选结果"
        @on-sort-change="handleSortChanged"
        @on-select="handleSelectRow"
        @on-select-cancel="handleCancelRow"
        @on-select-all="handleSelectAll"
        @on-select-all-cancel="handleSelectAll">
        <template slot-scope="{ row }" slot="avatars">
          <div class="tabBox_img" v-viewer>
            <img v-lazy="row.avatar" />
          </div>
        </template>
        <template slot-scope="{ row }" slot="nickname">
          <div class="acea-row row-middle">
            <Icon type="md-male" v-show="row.sex === '男'" color="#2db7f5" size="15" class="mr5" />
            <Icon type="md-female" v-show="row.sex === '女'" color="#ed4014" size="15" class="mr5" />
            <Icon type="md-sad" v-show="row.sex === '保密'" color="#000" size="15" class="mr5" />
            <Divider type="vertical" />
            <span v-text="row.nickname"></span>
          </div>
          <div v-if="row.is_del == 1" style="color: red">用户已注销</div>
          <!--<div v-show="row.vip_name" class="vipName">{{row.vip_name}}</div>-->
        </template>
        <template slot-scope="{ row }" slot="isMember">
          <!--<div>{{ row.isMember ? '是' : '否' }}</div>-->
          <Tag color="default" v-if="!row.isMember">否</Tag>
          <Tag color="success" v-else>是</Tag>
        </template>
        <template slot-scope="{ row }" slot="status">
          <Tag color="success" v-if="!row.status">正常</Tag>
          <Tag color="error" v-else>禁止</Tag>
          <!--<i-switch v-model="row.status" :value="row.status" :true-value="0" :false-value="1" @on-change="handleChangeIsShow(row)" size="large">-->
          <!--  <span slot="open">显示</span>-->
          <!--  <span slot="close">隐藏</span>-->
          <!--</i-switch>-->
        </template>
        <template slot-scope="{ row, index }" slot="action">
          <template v-if="row.is_del != 1">
            <a @click="getUsersDetail(row)">详情</a>
            <Divider type="vertical" />
            <Dropdown @on-click="changeMenu(row, $event, index)" :transfer="true">
              <a href="javascript:void(0)">更多<Icon type="ios-arrow-down"></Icon></a>
              <DropdownMenu slot="list">
                <!-- <DropdownItem name="1">编辑</DropdownItem> -->
                <DropdownItem name="2">积分余额</DropdownItem>
                <DropdownItem name="3">赠送会员</DropdownItem>
                <DropdownItem name="4" v-if="row.vip_name">清除等级</DropdownItem>
                <DropdownItem name="5">设置分组</DropdownItem>
                <DropdownItem name="6">设置标签</DropdownItem>
                <DropdownItem name="7">修改上级推广人</DropdownItem>
                <DropdownItem name="8" v-if="row.spread_uid">清除上级推广人</DropdownItem>
                <DropdownItem name="9" divided><a href="javascript: void(0);" class="l-color-error">封禁</a></DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </template>
          <template v-else>
            <div v-if="row.is_del == 1" style="color: red">已注销</div>
          </template>
        </template>
      </Table>

      <div class="acea-row row-right l-m-t10">
        <Page :total="total" :current="usersFromWhere.page" show-elevator show-total @on-change="handlePageChange" :page-size="usersFromWhere.limit"/>
      </div>
    </Card>

    <!-- 会员详情 -->
    <users-details ref="userDetails"></users-details>
    <!-- 会员编辑 -->
    <users-edit ref="userEdit" :usesr-data="usersData"></users-edit>
    <!-- 发送优惠券-->
    <send-coupone ref="sends" :userIds="ids.toString()"></send-coupone>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mixins from '@/mixins'
import expandRow from './expandRow.vue'
import {
  getUsersListApi,
  getUserInfoApi
} from '@/api/users'

import UsersDetails from './components/UserDetails.vue'
import UsersEdit from './components/usersEdit.vue'

export default {
  name: 'index',
  mixins: [mixins],
  components: {
    UsersDetails,
    UsersEdit
  },
  data () {
    return {
      // 用户类型
      headeTabs: [
        { type: '', name: '全部' },
        { type: 'wechat', name: '微信公众号' },
        { type: 'routine', name: '微信小程序' },
        { type: 'h5', name: 'H5' },
        { type: 'pc', name: 'PC' },
        { type: 'app', name: 'APP' }
      ],
      // 查询条件
      usersFromWhere: {
        page: 1,
        limit: 10
      },
      // 用户模糊搜索键
      field_key: '',
      // 隐藏的搜索条件展开收缩
      collapse: false,
      // 用户标签数据
      usersLabelData: [],
      // 下单次数
      pay_count: '',
      // 分组
      group_id: '',
      usersGroupDataList: [],
      // 等级
      level_id: '',
      usersLevelDataList: [],
      // 访问时间
      user_time_type: '',
      timeVal: '',
      options: {
        shortcuts: [
          {
            text: '今天',
            value () {
              const end = new Date()
              const start = new Date()
              start.setTime(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()))
              return [start, end]
            }
          },
          {
            text: '昨天',
            value () {
              const end = new Date()
              const start = new Date()
              start.setTime(
                start.setTime(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1))
              )
              end.setTime(
                end.setTime(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1))
              )
              return [start, end]
            }
          },
          {
            text: '最近7天',
            value () {
              const end = new Date()
              const start = new Date()
              start.setTime(
                start.setTime(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 6))
              )
              return [start, end]
            }
          },
          {
            text: '最近30天',
            value () {
              const end = new Date()
              const start = new Date()
              start.setTime(
                start.setTime(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 29))
              )
              return [start, end]
            }
          },
          {
            text: '本月',
            value () {
              const end = new Date()
              const start = new Date()
              start.setTime(start.setTime(new Date(new Date().getFullYear(), new Date().getMonth(), 1)))
              return [start, end]
            }
          },
          {
            text: '本年',
            value () {
              const end = new Date()
              const start = new Date()
              start.setTime(start.setTime(new Date(new Date().getFullYear(), 0, 1)))
              return [start, end]
            }
          }
        ]
      },
      // 居住城市
      address: '',
      addresDataList: [],
      // 多选用户
      selectionUsersList: [],
      // 用户数据
      usersListData: [],
      usersColumns: [
        {
          type: 'expand',
          width: 40,
          render: (h, params) => {
            return h(expandRow, {
              props: {
                row: params.row
              }
            })
          }
        },
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: '用户ID',
          key: 'uid',
          width: 80,
          align: 'center'
        },
        {
          title: '头像',
          slot: 'avatars',
          minWidth: 60,
          align: 'center'
        },
        {
          title: '姓名',
          slot: 'nickname',
          minWidth: 130,
          align: 'center'
        },
        {
          title: '付费会员',
          slot: 'isMember',
          minWidth: 90,
          align: 'center'
        },
        {
          title: '用户等级',
          key: 'level',
          minWidth: 90,
          align: 'center'
        },
        {
          title: '分组',
          key: 'group_id',
          minWidth: 100,
          align: 'center'
        },
        {
          title: '手机号',
          key: 'phone',
          minWidth: 100,
          align: 'center'
        },
        {
          title: '用户类型',
          key: 'user_type',
          minWidth: 100,
          align: 'center'
        },
        {
          title: '余额',
          key: 'now_money',
          sortable: 'custom',
          minWidth: 100,
          align: 'center'
        },
        {
          title: '状态',
          slot: 'status',
          minWidth: 100,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'action',
          fixed: 'right',
          minWidth: 120,
          align: 'center'
        }
      ],
      // 选中合并项的id
      selectedIds: new Set(),
      ids: [],
      // 初始化新增用户表单
      usersData: {}
    }
  },
  computed: {
    ...mapState('layout', ['isMobile']),
    labelWidth () {
      return this.isMobile ? undefined : 100
    },
    labelPosition () {
      return this.isMobile ? 'top' : 'right'
    }
  },
  mounted () {
    this.getUsersListRequest()
  },
  methods: {
    // 获取用户数据
    getUsersListRequest () {
      if (this.selectionUsersList.length) {
        let activeIds = []
        this.selectionUsersList.forEach((item) => {
          activeIds.push(item.id)
        })
        this.usersFromWhere.label_id = activeIds.join(',')
      }
      this.usersFromWhere.user_type = this.usersFromWhere.user_type || ''
      this.usersFromWhere.status = this.usersFromWhere.status || ''
      this.usersFromWhere.sex = this.usersFromWhere.sex || ''
      this.usersFromWhere.is_promoter = this.usersFromWhere.is_promoter || ''
      this.usersFromWhere.country = this.usersFromWhere.country || ''
      this.usersFromWhere.pay_count = this.pay_count === 'all' ? '' : this.pay_count
      this.usersFromWhere.user_time_type = this.user_time_type === 'all' ? '' : this.user_time_type
      this.usersFromWhere.field_key = this.field_key === 'all' ? '' : this.field_key
      this.usersFromWhere.level = this.level === 'all' ? '' : this.level
      this.usersFromWhere.group_id = this.group_id === 'all' ? '' : this.group_id
      this.loading = true
      getUsersListApi(this.usersFromWhere).then(async (res) => {
        let data = res.data
        this.usersListData = data.list
        this.total = data.count
        this.loading = false
        this.$nextTick(() => {
          this.handleSetChecked()
        })
      }).catch((res) => {
        this.loading = false
        this.$Message.error(res.msg)
      })
    },
    // 处理用户类型选择事件
    handleClickTab (type) {
      this.usersFromWhere.page = 1
      this.usersFromWhere.user_type = type
      this.getUsersListRequest()
    },
    openSelectLabel () {
      this.selectLabelShow = true
    },
    // 是否为付费会员；
    handleChangeMember () {
      this.usersFromWhere.page = 1
      this.getUsersListRequest()
    },
    // 具体日期
    handleChangeTime (e) {
      this.timeVal = e
      this.usersFromWhere.user_time = this.timeVal.join('-')
    },
    // 选择国家
    handleChangeCountry () {
      if (this.usersFromWhere.country === 'abroad' || !this.usersFromWhere.country) {
        this.selectedData = []
        this.usersFromWhere.province = ''
        this.usersFromWhere.city = ''
        this.address = []
      }
    },
    // 选择地址
    handleChange (value, selectedData) {
      this.selectedData = selectedData.map((o) => o.label)
      this.usersFromWhere.province = this.selectedData[0]
      this.usersFromWhere.city = this.selectedData[1]
    },
    // 处理搜索
    handleUsersSearchs () {
      this.getUsersListRequest(this.usersFromWhere)
    },
    // 处理搜索重置
    handleUsersReset () {},
    // 新增用户
    handleUsersCreate (row) {
      this.getUserFrom(row.uid)
    },
    // 获取编辑表单数据
    getUserFrom (id) {
      getUserInfoApi(id).then(async (res) => {
        this.$refs.userEdit.modals = true
        this.usersData = res.data
      }).catch((res) => {
        this.$Message.error(res.msg)
      })
    },
    // 处理发放优惠券
    handleUsersSendTicket () {
      if (this.ids.length === 0) {
        this.$Message.warning('请选择要发送优惠券的用户')
      } else {
        this.$refs.sends.modals = true
        this.$refs.sends.getListRequest()
      }
    },
    // 处理设置用户分组
    handleUsersSetGroup () {},
    // 处理设置用户等级
    handleUsersSetLabel () {},
    // 处理用户导出
    handleUsersExportList () {},
    // 处理公众号用户同步
    handleUsersSynchro () {},
    // 处理表格用户数据排序
    handleSortChanged () {},
    // 处理表格选择的某一个用户
    handleSelectRow (row) {
      this.selectedIds.add(row.uid)
      this.$nextTick(() => {
        // 确保dom加载完毕
        this.handleSetChecked()
      })
    },
    // 处理取消表格选择的用户
    handleCancelRow () {},
    // 处理表格用户全选
    handleSelectAll () {},
    // 处理用户分页搜索
    handlePageChange (index) {
      this.usersFromWhere.page = index
      this.getUsersListRequest()
    },
    // 处理已选中的用户
    handleSetChecked () {
      // 将new Set()转化为数组
      this.ids = [...this.selectedIds]
      // 找到绑定的table的ref对应的dom，找到table的objData对象，objData保存的是当前页的数据
      let objData = this.$refs.table.objData
      for (let index in objData) {
        if (this.selectedIds.has(objData[index].uid)) {
          objData[index]._isChecked = true
        }
      }
    },
    // 处理用户的状态
    handleChangeIsShow () {},
    // 展开用户信息抽屉
    getUsersDetail (row) {
      this.$refs.userDetails.modals = true
      this.$refs.userDetails.getUsersDetails(row.uid)
    }
  }
}
</script>

<style scoped lang="scss">
.userFrom {
  /deep/ .ivu-form-item-content {
    margin-left: 0px !important;
  }
}

.labelInput {
  border: 1px solid #dcdee2;
  padding: 0 6px;
  border-radius: 5px;
  min-height: 30px;
  cursor: pointer;

  .span {
    color: #c5c8ce;
  }

  .ivu-icon-ios-arrow-down {
    font-size: 14px;
    color: #808695;
  }
}

.ivu-text-right {
  text-align: right;
}

.tabBox_img {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
}

.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
</style>
