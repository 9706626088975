<template>
  <div style="width: 100%">
    <Drawer
      title="添加新用户"
      :closable="false"
      width="1100"
      scrollable
      v-model="modals">
      <div class="usersInfo">
        <Form
          ref="formItem"
          :rules="ruleValidate"
          :model="formItem"
          :label-width="100"
          @submit.native.prevent>
          <FormItem label="用户ID：" v-if="formItem.uid">
            <Input class="form-sty" disabled v-model="formItem.uid" placeholder="请输入编号" style="width: 80%"></Input>
          </FormItem>
          <FormItem label="真实姓名：" prop="real_name">
            <Input class="form-sty" v-model.trim="formItem.real_name" placeholder="请输入真实姓名" style="width: 80%"></Input>
          </FormItem>
          <FormItem label="手机号码：" prop="phone">
            <Input class="form-sty" v-model="formItem.phone" placeholder="请输入手机号码" style="width: 80%"></Input>
          </FormItem>
          <FormItem label="生日：">
            <DatePicker
              class="form-sty"
              type="date"
              :value="formItem.birthday"
              placeholder="请选择生日"
              style="width: 80%"
              format="yyyy-MM-dd"
              @on-change="formItem.birthday = $event"></DatePicker>
          </FormItem>
          <FormItem label="身份证号：">
            <Input class="form-sty" v-model.trim="formItem.card_id" placeholder="请输入身份证号" style="width: 80%"></Input>
          </FormItem>
          <FormItem label="用户地址：">
            <Input class="form-sty" v-model="formItem.addres" placeholder="请输入用户地址" style="width: 80%"></Input>
          </FormItem>
          <FormItem label="用户备注：">
            <Input class="form-sty" v-model="formItem.mark" placeholder="请输入用户备注" style="width: 80%"></Input>
          </FormItem>
          <FormItem label="登录密码：" prop="pwd">
            <Input class="form-sty" type="password" v-model="formItem.pwd" placeholder="请输入登录密码（修改用户可不填写，不填写不修改原密码）" style="width: 80%"></Input>
          </FormItem>
          <FormItem label="确认密码：" prop="true_pwd">
            <Input class="form-sty" type="password" v-model="formItem.true_pwd" placeholder="请输入确认密码（修改用户可不填写，不填写不修改原密码）" style="width: 80%"></Input>
          </FormItem>
          <FormItem label="用户等级：">
            <Select v-model="formItem.level" class="form-sty" clearable>
              <Option v-for="(item, index) in infoData.levelInfo" :key="index" :value="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem label="用户分组：">
            <Select v-model="formItem.group_id" class="form-sty" clearable>
              <Option v-for="(item, index) in infoData.groupInfo" :key="index" :value="item.id">{{ item.group_name }}</Option>
            </Select>
          </FormItem>
          <FormItem label="用户标签：">
            <div class="acea-row row-middle">
              <Tag closable v-for="(item, index) in dataLabel" :key="index" @on-close="handleCloseLabel(item)">{{ item.label_name }}</Tag>
              <span class="addfont" @click="handleOpenLabel">选择用户关联标签</span>
              <span class="addfont" @click="handleAddLabel">新增标签</span>
            </div>
          </FormItem>
          <FormItem label="推广资格：">
            <RadioGroup v-model="formItem.spread_open" class="form-sty">
              <Radio :label="1">启用</Radio>
              <Radio :label="0">禁用</Radio>
            </RadioGroup>
            <div class="tip">禁用用户的推广资格后，在任何分销模式下该用户都无分销权限</div>
          </FormItem>
          <FormItem label="推广权限：">
            <RadioGroup v-model="formItem.is_promoter" class="form-sty">
              <Radio :label="1">开启</Radio>
              <Radio :label="0">锁定</Radio>
              <div class="tip">指定分销模式下，开启或关闭用户的推广权限</div>
            </RadioGroup>
          </FormItem>
          <FormItem label="用户状态：">
            <RadioGroup v-model="formItem.status" class="form-sty">
              <Radio :label="1">开启</Radio>
              <Radio :label="0">锁定</Radio>
            </RadioGroup>
          </FormItem>
        </Form>

        <Modal
          v-model="labelShow"
          scrollable
          title="请选择用户标签"
          :closable="false"
          width="500"
          :footer-hide="true">
          <UsersLabel
            v-if="labelShow"
            :only_get="true"
            :uid="formItem.uid"
            @close="handleLabelClose"
            @activeData="handleActiveData"></UsersLabel>
        </Modal>
      </div>

      <div class="demo-drawer-footer">
        <Button style="margin-right: 8px" @click="modals = false">取消</Button>
        <Button type="primary" @click="handleCreateUsers">提交</Button>
      </div>
    </Drawer>
  </div>
</template>

<script>
import {
  addUsersLabelApi,
  saveUsersInfoApi,
  updateUsersInfoApi
} from '@/api/users'

export default {
  name: 'usersEdit',
  props: {
    usesrData: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      modals: false,
      labelShow: false,
      // 表单数据及规则
      formItem: {
        uid: 0,
        real_name: '',
        phone: '',
        birthday: '',
        card_id: '',
        addres: '',
        mark: '',
        pwd: '',
        true_pwd: '',
        level: '',
        group_id: '',
        label_id: [],
        spread_open: 0,
        is_promoter: 0,
        status: 1
      },
      ruleValidate: {
        real_name: [{ required: true, message: '请输入用户姓名', type: 'string', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入用户手机号', type: 'string', trigger: 'blur' }],
        pwd: [{ required: true, message: '请输入密码', type: 'string', trigger: 'blur' }],
        true_pwd: [{ required: true, message: '请输入确认密码', type: 'string', trigger: 'blur' }]
      },
      groupInfo: [],
      labelInfo: [],
      levelInfo: [],
      infoData: {
        groupInfo: [],
        labelInfo: [],
        levelInfo: []
      },
      dataLabel: []
    }
  },
  mounted () {
    this.$set(this.infoData, 'groupInfo', this.usesrData.groupInfo)
    this.$set(this.infoData, 'levelInfo', this.usesrData.levelInfo)
    this.$set(this.infoData, 'labelInfo', this.usesrData.labelInfo)
    let arr = Object.keys(this.formItem)
    if (this.usesrData.userInfo) {
      arr.map((i) => {
        this.formItem[i] = this.usesrData.userInfo[i]
      })
      if (!this.formItem.birthday) this.formItem.birthday = ''
      if (this.formItem.label_id.length) {
        this.dataLabel = this.formItem.label_id
      }
    } else {
      this.handleReset()
    }
  },
  methods: {
    // 处理取消选择用户标签
    handleCloseLabel (label) {
      let index = this.dataLabel.indexOf(this.dataLabel.filter((d) => d.id == label.id)[0])
      this.dataLabel.splice(index, 1)
    },
    // 处理选择的标签
    handleOpenLabel () {
      this.labelShow = true
      this.$nextTick(() => {
        // console.log(this.$refs.usersLabel)
        // this.$refs.UsersLabel.UsersLabel(JSON.parse(JSON.stringify(this.infoData.labelInfo)))
      })
    },
    // 打开新增标签动态表单
    handleAddLabel () {
      this.$modalForm(addUsersLabelApi(0)).then(() => {})
    },
    // 标签弹窗关闭
    handleLabelClose () {
      this.labelShow = false
    },
    handleActiveData (dataLabel) {
      this.labelShow = false
      this.dataLabel = dataLabel
    },
    // 保存新增的用户数据
    handleCreateUsers () {
      let data = this.$refs.userEdit.formItem
      let ids = []
      this.$refs.userEdit.dataLabel.map((i) => {
        ids.push(i.id)
      })
      data.label_id = ids
      if (data.uid) {
        updateUsersInfoApi(data).then((res) => {
          this.modals = false
          this.$Message.success(res.msg)
          this.getList()
        }).catch((err) => {
          this.$Message.error(err.msg)
        })
      } else {
        saveUsersInfoApi(data).then((res) => {
          this.modals = false
          this.$Message.success(res.msg)
          this.getList()
        }).catch((err) => {
          this.$Message.error(err.msg)
        })
      }
    },
    // 重置数据
    handleReset () {
      this.formItem = {
        uid: '',
        real_name: '',
        phone: '',
        birthday: '',
        card_id: '',
        addres: '',
        mark: '',
        pwd: '',
        true_pwd: '',
        level: '',
        group_id: '',
        label_id: [],
        spread_open: 0,
        is_promoter: 0,
        status: 1
      }
    }
  }
}
</script>

<style scoped lang="scss">
.usersInfo {
  height: calc(100% - 60px);
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0;
  }
}

.addfont {
  display: inline-block;
  font-size: 13px;
  font-weight: 400;
  color: #1890ff;
  margin-left: 14px;
  cursor: pointer;
  margin-left: 10px;
}
</style>
